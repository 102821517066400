<template>
  <div class="relative isolate pb-32 pt-24 sm:pt-32 z-10">
    <div class="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-10 blur-3xl" aria-hidden="true">
      <div class="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
    </div>
    <div class="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-10 blur-3xl sm:pt-40 xl:justify-end" aria-hidden="true">
      <div class="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
    </div>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-xl text-center">
        <h2 class="text-lg font-semibold leading-8 tracking-tight text-orange-600">
          Our Customer Testimonials
        </h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Don't just take our word for it
        </p>
      </div>
      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-white sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
        <figure class="col-span-2 hidden sm:block sm:rounded-2xl sm:bg-darkgray-800 sm:shadow-lg sm:ring-1 sm:ring-gray-900/5 xl:col-start-2 xl:row-end-1">
          <blockquote class="p-12 text-3xl font-semibold leading-10 tracking-tight text-white">
            <p>{{ `“${featuredTestimonial.body}”` }}</p>
          </blockquote>
          <figcaption class="flex items-center gap-x-4 border-t border-darkgray-900/10 px-6 py-4">
            <img class="h-10 w-10 flex-none rounded-full bg-darkgray-500" :src="featuredTestimonial.author.imageUrl" alt="" />
            <div class="flex-auto">
              <div class="font-semibold">
                {{ featuredTestimonial.author.name }}
              </div>
              <div class="text-gray-200">
                {{ `${featuredTestimonial.author.handle}` }}
              </div>
            </div>
            <img class="h-4 w-auto flex-none" src="~/assets/img/logos/pixomondo.png" loading="lazy" fetchpriority="low" alt="Pixomondo logo" />
          </figcaption>
        </figure>
        <div v-for="(columnGroup, columnGroupIdx) in testimonials" :key="columnGroupIdx" class="space-y-8 xl:contents xl:space-y-0">
          <div v-for="(column, columnIdx) in columnGroup" :key="columnIdx" :class="[(columnGroupIdx === 0 && columnIdx === 0) || (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1) ? 'xl:row-span-2' : 'xl:row-start-1', 'space-y-8']">
            <figure v-for="testimonial in column" :key="testimonial.author.handle" class="rounded-2xl bg-darkgray-800 p-6 shadow-lg ring-1 ring-gray-900/5">
              <blockquote class="text-white text-base">
                <p>{{ `“${testimonial.body}”` }}</p>
              </blockquote>
              <figcaption class="mt-6 flex items-center gap-x-4">
                <img class="h-10 w-10 rounded-full bg-darkgray-500" :src="testimonial.author.imageUrl" alt="" />
                <div>
                  <div class="font-semibold">
                    {{ testimonial.author.name }}
                  </div>
                  <div class="text-gray-200">
                    {{ `${testimonial.author.handle}` }}
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import mujia from '~/assets/img/quotes/mujia.jpg';
import nickmarshall from '~/assets/img/quotes/nickmarshall.jpg';
import dietermagallan from '~/assets/img/quotes/dietermagallan.jpg';
import melainamace from '~/assets/img/quotes/melainamace.jpg';
import juancarlosbarquet from '~/assets/img/quotes/juancarlosbarquet.jpg';
import seppsonntag from '~/assets/img/quotes/seppsonntag.jpg';
import jamesvincent from '~/assets/img/quotes/jamesvincent.jpg';

const featuredTestimonial = {
  body: 'What used to take 2-3 weeks, now takes just a few hours!',
  author: {
    name: 'Mujia Liao',
    handle: 'Head of Virtual-Art Department',
    imageUrl: mujia
  },
}
const testimonials = [
  [
    [
      {
        body: 'This is the reference website we have been dreaming of for the past decade!',
        author: {
          name: 'Nick Marshall',
          handle: 'Director Of Visual Effects, DNEG',
          imageUrl: nickmarshall
        },
      },
      {
        body: 'There’s simply no other option for skies now, you guys have so many to choose from.',
        author: {
          name: 'Dieter Magallan',
          handle: 'Matte Painting Supervisor, PIXOMONDO',
          imageUrl: dietermagallan
        },
      },
    ],
    [
      {
        body: 'MattePaint is truly invaluable.',
        author: {
          name: 'Melaina Mace',
          handle: 'CG Supervisor, DNEG',
          imageUrl: melainamace,
        },
      },
      // More testimonials...
    ],
  ],
  [
    [
      {
        body: 'MattePaint allows us to focus on storytelling',
        author: {
          name: 'Juan Carlos Barquet',
          handle: 'Environments Lead, DNEG',
          imageUrl: juancarlosbarquet,
        },
      },
      // More testimonials...
    ],
    [
      {
        body: 'MattePaint is pushing the boundaries of HDRI capture with ultra dynamic range and extremely high resolutions.',
        author: {
          name: 'Sepp Sonntag',
          handle: 'Virtual Art Department Supervisor, PIXOMONDO',
          imageUrl: seppsonntag,
        },
      },
      {
        body: 'Matte paint has become critical to my work flow. The quality is second to none and they are constantly updating the library off assets!',
        author: {
          name: 'James Vincent',
          handle: 'Archviz Supervisor',
          imageUrl: jamesvincent,
        },
      },
    ],
  ],
]
</script>